import { Report, SchedulingData, SchedulingOptions } from '../types';
import { VariableModel } from 'app/features/variables/types';
import { hasOptions } from 'app/features/variables/guard';
import { variableAdapters } from 'app/features/variables/adapters';
import config from 'app/core/config';

/**
 * Move hour and minute from time to schedule
 * @param scheduleData
 */
export const getSchedule = (scheduleData = {} as SchedulingData): SchedulingOptions => {
  const { time, ...schedule } = scheduleData;
  return { ...schedule, ...time } as SchedulingOptions;
};

/**
 * Convert variable values to CSV and remove all empty keys before sending to backend
 * @param variables
 */
export const variablesToCsv = (variables?: VariableModel[]) => {
  if (!variables?.length) {
    return {};
  }

  return Object.fromEntries(
    variables.map((variable) => {
      const { getValueForUrl } = variableAdapters.get(variable.type);
      const value = getValueForUrl(variable);
      return [variable.name, Array.isArray(value) ? value.join(',') : value];
    })
  );
};

export const applyDefaultVariables = (variables: VariableModel[], reportVariables?: Report['templateVars']) => {
  if (!reportVariables || !Object.keys(reportVariables).length) {
    return variables;
  }

  return variables.map((variable) => {
    const reportVariable = reportVariables[variable.name];
    if (!reportVariable || !hasOptions(variable)) {
      return variable;
    }

    const split = reportVariable.split(',');
    const values = split
      .map((str) => variable.options.find((opt) => opt.value === str) || { text: str, value: str })
      .filter(Boolean);

    return {
      ...variable,
      current: { ...variable.current, text: values.map((val) => val?.text), value: values.map((val) => val?.value) },
      options: variable.options.map((option) => ({
        ...option,
        selected: typeof option.value === 'string' && split.includes(option.value),
      })),
    };
  });
};

// Get renderer version from its config field, return 1 by default
export const getRendererMajorVersion = (): number => {
  const version = config.rendererVersion;
  const semverRegex = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
  const res = semverRegex.exec(version);

  let majorVersionStr = '';
  if (res && res.length > 1) {
    majorVersionStr = res[1];
  }

  if (majorVersionStr === '') {
    return 1;
  }

  let majorVersion = parseInt(majorVersionStr, 10);
  if (isNaN(majorVersion)) {
    majorVersion = 1;
  }

  return majorVersion;
};
