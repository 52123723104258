import React, { FC } from 'react';
import { capitalize } from 'lodash';
import { css } from '@emotion/css';
import { DeleteButton, useStyles2, Tooltip, CardContainer, Button } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { Report, SchedulingFrequency, SchedulingOptions, AccessControlAction } from '../types';
import { contextSrv } from 'app/core/core';

interface Props {
  reports: Report[];
  deleteReport: (report: Report) => void;
  updateReport: (report: Report) => void;
  filter: string;
}

function parseScheduleTime(schedule: SchedulingOptions) {
  let time = '';

  const minute = schedule.minute === 0 ? '00' : schedule.minute;

  switch (schedule.frequency) {
    case SchedulingFrequency.Monthly:
      time = `Monthly ${
        isNaN(parseInt(schedule.dayOfMonth!, 10)) ? `on ${schedule.dayOfMonth} day` : `on day ${schedule.dayOfMonth}`
      } at ${schedule.hour}:${minute}`;
      break;
    case SchedulingFrequency.Weekly:
      time = `Every ${capitalize(schedule.day)} at ${schedule.hour}:${minute}`;
      break;
    case SchedulingFrequency.Daily:
      time = `Daily at ${schedule.hour}:${minute}`;
      break;
    case SchedulingFrequency.Hourly:
      time = `Hourly at minute ${minute}`;
      break;
    case SchedulingFrequency.Never:
      time = `Never`;
      break;
  }

  return time;
}

const filterReports = (reports: Report[], filter: string) => {
  const filterFields: Array<keyof Pick<Report, 'name' | 'dashboardName' | 'recipients'>> = [
    'name',
    'dashboardName',
    'recipients',
  ];

  return reports.filter((report) => {
    return filterFields.some((field) => report[field].toLowerCase().includes(filter.toLowerCase()));
  });
};

export const ReportList: FC<Props> = ({ deleteReport, updateReport, reports, filter }) => {
  const styles = useStyles2(getStyles);

  const toggleReportState = (report: Report) => {
    updateReport({ ...report, state: report.state !== 'paused' ? 'paused' : 'scheduled' });
  };

  const canEditReport = contextSrv.hasPermission(AccessControlAction.ReportingAdminWrite);
  const canDeleteReport = contextSrv.hasPermission(AccessControlAction.ReportingDelete);

  return (
    <div>
      {filterReports(reports, filter).map((report) => {
        const splitRecipients = report.recipients.split(';');
        const numRecipients = splitRecipients.length;
        const scheduleTime = parseScheduleTime(report.schedule);

        return (
          <CardContainer key={report.id} href={`reports/edit/${report.id}`} className={styles.container}>
            <div className={styles.info}>
              <span className={styles.title}>{report.name}</span>
              <span>{report.dashboardName}</span>
            </div>
            <div className={styles.recipients}>
              <Tooltip content={splitRecipients.join(', ')} placement={'top'}>
                <div>{`${numRecipients} recipient${numRecipients > 1 ? 's' : ''}`}</div>
              </Tooltip>
            </div>
            <div className={styles.schedule}>
              <span className={styles.scheduleStatus}>
                {scheduleTime !== 'Never' && (report.state || 'scheduled').toUpperCase()}
              </span>
              <span>{parseScheduleTime(report.schedule)}</span>
            </div>
            <div className={styles.buttonWrapper}>
              <Tooltip content={`${report.state !== 'paused' ? 'Pause' : 'Resume'} report`} placement={'top'}>
                <Button
                  variant={'secondary'}
                  icon={report.state !== 'paused' ? 'pause' : 'play'}
                  size={'sm'}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleReportState(report);
                  }}
                  disabled={report.schedule.frequency === 'never' || !canEditReport}
                />
              </Tooltip>
              <DeleteButton size="sm" onConfirm={() => deleteReport(report)} disabled={!canDeleteReport} />
            </div>
          </CardContainer>
        );
      })}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      '& > a': {
        alignItems: 'center',
      },
    }),
    info: css({
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }),
    title: css({
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
    }),
    recipients: css({
      display: 'flex',
      width: '30%',
      textDecoration: 'underline',
      cursor: 'pointer',
    }),
    schedule: css({
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }),
    scheduleStatus: css({
      textTransform: 'uppercase',
    }),
    buttonWrapper: css({
      display: 'flex',
      alignItems: 'center',
      width: '10%',
      '& > button': {
        marginRight: theme.spacing(2),
      },
    }),
  };
};
