import React, { FC } from 'react';
import {
  TimeOfDayPicker,
  TimeZonePicker,
  Select,
  RadioButtonGroup,
  Field,
  HorizontalGroup,
  FieldSet,
  InputControl,
  FormAPI,
} from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { daysOfWeek, daysOfMonth, SchedulingFrequency, SchedulingOptions, ReportFormData } from '../types';

interface Props extends Pick<FormAPI<ReportFormData>, 'control' | 'watch'> {
  schedulingOptions: SchedulingOptions;
  disabled?: boolean;
  fieldClassName?: string;
}

const frequencyOptions: SelectableValue[] = [
  { label: 'Monthly', value: SchedulingFrequency.Monthly },
  { label: 'Weekly', value: SchedulingFrequency.Weekly },
  { label: 'Daily', value: SchedulingFrequency.Daily },
  { label: 'Hourly', value: SchedulingFrequency.Hourly },
  { label: 'Never', value: SchedulingFrequency.Never },
];

export const ReportScheduling: FC<Props> = ({ schedulingOptions, disabled, control, watch, fieldClassName }) => {
  const { frequency, hour, minute, timeZone } = schedulingOptions;
  //@ts-ignore
  const watchFrequency = watch('schedule.frequency', frequency);

  const selectedDayOfWeek = daysOfWeek.find((day) => schedulingOptions.day === day.value);
  const selectedDayOfMonth = daysOfMonth.find((day) => schedulingOptions.dayOfMonth === day.value);
  return (
    <FieldSet label="Schedule and send">
      <Field className={fieldClassName} label="Frequency">
        <InputControl
          defaultValue={frequency}
          name="schedule.frequency"
          render={({ field: { ref, ...field } }) => (
            <RadioButtonGroup {...field} fullWidth options={frequencyOptions} disabled={disabled} />
          )}
          control={control}
        />
      </Field>
      {watchFrequency === SchedulingFrequency.Monthly && (
        <Field
          className={fieldClassName}
          label="Day in month"
          description="If you set 29-31, the report will not be sent every month. Choose 'Last' to send the report on the last day of every month."
        >
          <InputControl
            name="schedule.dayOfMonth"
            render={({ field: { onChange, ref, ...field } }) => (
              <Select
                {...field}
                onChange={(selected) => onChange(selected.value)}
                options={daysOfMonth}
                disabled={disabled}
              />
            )}
            control={control}
            defaultValue={selectedDayOfMonth?.value}
          />
        </Field>
      )}
      {watchFrequency === SchedulingFrequency.Weekly && (
        <Field className={fieldClassName} label="Day">
          <InputControl
            name="schedule.day"
            defaultValue={selectedDayOfWeek?.value}
            render={({ field: { onChange, ref, ...field } }) => (
              <Select
                {...field}
                onChange={(selected) => onChange(selected.value)}
                options={daysOfWeek}
                disabled={disabled}
              />
            )}
            control={control}
          />
        </Field>
      )}
      <HorizontalGroup justify="space-between" width={'100%'}>
        {[SchedulingFrequency.Weekly, SchedulingFrequency.Monthly, SchedulingFrequency.Daily].includes(
          watchFrequency as SchedulingFrequency
        ) && (
          <Field className={fieldClassName} label="Time">
            <InputControl
              name="schedule.time"
              render={({ field: { onChange, ref, ...field } }) => (
                <TimeOfDayPicker
                  {...field}
                  minuteStep={10}
                  disabled={disabled}
                  onChange={(selected) =>
                    onChange({
                      hour: selected.hour?.(),
                      minute: selected.minute?.(),
                    })
                  }
                />
              )}
              defaultValue={{ hour, minute } as any}
              control={control}
            />
          </Field>
        )}

        {watchFrequency === SchedulingFrequency.Hourly && (
          <Field className={fieldClassName} label="At minute">
            <InputControl
              name="schedule.time"
              render={({ field: { onChange, ref, ...field } }) => (
                <TimeOfDayPicker
                  {...field}
                  showHour={false}
                  minuteStep={10}
                  disabled={disabled}
                  onChange={(selected) => {
                    onChange({
                      hour: selected.hour?.(),
                      minute: selected.minute?.(),
                    });
                  }}
                />
              )}
              defaultValue={{ hour, minute }}
              control={control}
            />
          </Field>
        )}
        {watchFrequency !== SchedulingFrequency.Never && (
          <Field className={fieldClassName} label="Time zone">
            <InputControl
              name="schedule.timeZone"
              render={({ field: { ref, ...field } }) => <TimeZonePicker {...field} width={30} disabled={disabled} />}
              defaultValue={timeZone}
              control={control}
            />
          </Field>
        )}
      </HorizontalGroup>
    </FieldSet>
  );
};
